<template>
	<div>
		<div class="row" v-if="!loading">
			<div class="col-12 col-md-6 offset-md-3">
				<div class="form-group" v-if="!inputError">
					<label for="icaoCode">{{ $t('weatherView.icaoInput') }}:</label>
					<input type="text" id="icaoCode" v-model.trim="icaoInput" class="form-control" autocorrect="off"
						autocapitalize="none" />
				</div>

				<div class="form-group has-validation" v-else>
					<label for="icaoCode">{{ $t('weatherView.icaoInput') }}:</label>
					<input type="text" id="icaoCode" v-model.trim="icaoInput" class="form-control is-invalid"
						autocorrect="off" autocapitalize="none" />
					<span class="invalid-feedback">{{ inputError }}</span>
				</div>

				<div class="form-group row">
					<div class="col-12">
						<button type="button" class="btn btn-primary w-100" v-on:click="getWeatherWithICAO()">
							<font-awesome-icon icon="search" class="mr-2" />
							{{ $t('weatherView.search') }}
						</button>
					</div>
          <div :class="`col-12 mt-2 d-flex align-items-center justify-content-center `" style="gap: 1rem">
            <SaveToBriefingButton v-if="weather" class="" :added="briefingReportAdded" @click="addWeatherToBriefing()" type="weather" />
            <a class="btn btn-ghost text-primary webcams " target="_blank" href="https://www.idokep.hu/webkamera/mindegyik">
              {{ $t('weatherView.webcams') }}<font-awesome-icon icon="camera" size="lg" class="ml-2" />
            </a>
          </div>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-center" v-if="loading">
			<loading-spinner />
		</div>
		<div class="weather-con" v-if="!loading && weather">
			<div class="d-flex w-100 flex-column justify-content-center align-items-center mt-2 mb-3">
        <h6 class="text-center" v-if="(weather.distance || {}).value" v-html="$t('weatherView.distanceFromAirport', {name: weather.distance.from.name, code: weather.distance.from.code, distance: Math.round(weather.distance.value)})"></h6>
				<h5 class="text-center">
					{{ weather.metar.airport.name }} ({{ weather.metar.airport.icao }})
				</h5>
			</div>

			<div class=" d-block d-md-flex row mb-5">
				<div class="d-block mt-4 col-md-6 text-center">
					<h6>{{ $t('weatherView.metar-message') }}</h6>
					<div class="message h-100">
						{{ this.weather.metar.message }}
					</div>
				</div>
				<div class="d-block mt-4 col-md-6 text-center">
					<h6>{{ $t('weatherView.taf-message') }}</h6>
					<div class="message h-100">
						{{ this.weather.taf.message }}
					</div>
				</div>
			</div>

			<div class="row mb-5">
				<div class="mt-3 col-6 col-md-4 col-lg-2">
					<div class="d-block rounded overflow-hidden">
						<h4 class="m-0 bg-success text-center text-white py-2 ">
							{{ weather.metar.wind.speed }} {{ weather.metar.wind.unit }}
						</h4>
						<div class="m-0 info-card-title text-center text-white py-2 ">
							{{ $t('weatherView.windSpeed') }}
						</div>
					</div>
				</div>
				<div class="mt-3 col-6 col-md-4 col-lg-2">
					<div class="d-block rounded overflow-hidden">
						<h4 class="m-0 bg-success text-center text-white py-2 ">
							{{ weather.metar.visibility.mainVisibility }}
						</h4>
						<div class="m-0 info-card-title text-center text-white py-2 ">
							{{ $t('weatherView.visibility') }}
						</div>
					</div>
				</div>
				<div class="mt-3 col-6 col-md-4 col-lg-2">
					<div class="d-block rounded overflow-hidden">
						<h4 class="m-0 bg-success text-center text-white py-2 ">
							{{ weather.metar.temperature }}°C
						</h4>
						<div class="m-0 info-card-title text-center text-white py-2 ">
							{{ $t('weatherView.temperature') }}
						</div>
					</div>
				</div>
				<div class="mt-3 col-6 col-md-4 col-lg-2">
					<div class="d-block rounded overflow-hidden">
						<h4 class="m-0 bg-success text-center text-white py-2 ">
							{{ weather.metar.dewPoint }}°C
						</h4>
						<div class="m-0 info-card-title text-center text-white py-2 ">
							{{ $t('weatherView.dewPoint') }}
						</div>
					</div>
				</div>
				<div class="mt-3 col-6 col-md-4 col-lg-2">
					<div class="d-block rounded overflow-hidden">
						<h4 class="m-0 bg-success text-center text-white py-2 ">
							{{ weather.metar.altimeter }} hPa
						</h4>
						<div class="m-0 info-card-title text-center text-white py-2 ">
							{{ $t('weatherView.altimeter') }}
						</div>
					</div>
				</div>
				<div class="mt-3 col-6 col-md-4 col-lg-2">
					<div class="d-block rounded overflow-hidden">
						<h4 class="m-0 bg-success text-center text-white py-2 ">
							{{ weather.metar.time | formatTime(false, true, "HH:mm:ss") }}
						</h4>
						<div class="m-0 info-card-title text-center text-white py-2 ">
							{{ $t('weatherView.time') }}
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div
					class="col-12 col-md-6 d-flex flex-column flex-sm-row flex-md-column justify-content-around align-items-center">
					<div>
						<vue-speedometer :height="200" :minValue="0" :maxValue="50" :value="weather.metar.wind.speed"
							:segments="3" :segmentColors="['#28A745', '#FFC109', '#DC3545']" :needleHeightRatio="0.8"
							:ringWidth="60" valueFormat="0" :forceRender="true" :customSegmentStops="[0, 15, 30, 50]"
							:currentValueText="`${$t('weatherView.windSpeed')}: \${value} ${weather.metar.wind.unit}`" />
					</div>
					<div class="d-block p-4 overflow-hidden">
						<WindCompass :direction="weather.metar.wind.directionDegrees"
							:runwayDirection="runwayDirection" />
					</div>
				</div>
				<div class="col-12 col-md-6">
					<Scatter :chart-options="chart.options" :chart-data="chart.data" :plugins="chart.plugins" />
				</div>
			</div>
			<div class="row mt-5 scroll-viewport overflow-hidden">
				<div class="d-block scroll-content  text-nowrap  ">
					<TafForecast v-for="f in forecast" v-bind:key="f.time + f.day" :day="f.day" :time="f.time"
						:visibility="f.visibility" :windDirection="f.windDirection" :windSpeed="f.windSpeed" />
				</div>
			</div>
		</div>

		<div class="mt-5 mx-3">
			<iframe class="w-100 rounded" height="600"
				src="https://embed.windy.com/embed2.html?lat=47.167&lon=19.446&detailLat=47.596&detailLon=19.353&zoom=7&level=surface&overlay=radar&product=radar&menu=&message=true&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
				frameborder="0"></iframe>
		</div>

	</div>
</template>

<script>

import VueSpeedometer from "vue-speedometer"
import { Scatter } from 'vue-chartjs/legacy'
import WindCompass from '@/components/weather/WindCompass.vue';
import TafForecast from "@/components/weather/TafForecast.vue";
import SaveToBriefingButton from '@/components/SaveToBriefingButton.vue'
import ScrollBooster from 'scrollbooster';
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	LineElement,
	CategoryScale,
	PointElement,
	LinearScale
} from 'chart.js'
import briefingmanager from "@/plugins/briefingmanager";
import LoadingSpinner from '@/components/LoadingSpinner.vue';


ChartJS.register(
	Title,
	Tooltip,
	Legend,
	LineElement,
	CategoryScale,
	PointElement,
	LinearScale,
)


var img = new Image(40, 28);
img.src = '/cloud.svg';

export default {
	name: 'WeatherView',
	components: { VueSpeedometer, Scatter, WindCompass, TafForecast, SaveToBriefingButton, LoadingSpinner },
	data() {
		return {
			/** @type {Weather|null} */
			weather: null,
			inputError: null,
			loading: false,
			forecast: [],
			icaoInput: "",
      briefingReportAdded: false,
			runwayDirection: null,
			chart: {
				data: {
					datasets: [
						{
							label: '',
							fill: '#0f0',
							backgroundColor: '#0f0',
							pointStyle: img,
							data: []
						},
					]
				},
				options: {
					scales: {
						y: {

							min: 0,
							max: 5000,
							ticks: {
								stepSize: 1000
							}
						},
						x: {
							min: 0,
							max: 100,
							display: false,
						}
					},
					responsive: true,
					maintainAspectRatio: false,
					tooltips: { enabled: false },
					hover: { mode: null },
					events: [],
					plugins: {
						legend: {
							display: false
						},
					}
				},
				plugins: [{
					beforeDraw: (chart) => {
						if (this.weather.metar.cavok && this.weather.metar.clouds.length == 0) {
							let width = chart.width;
							let height = chart.height;
							let ctx = chart.ctx;

							ctx.restore();
							ctx.font = "2rem sans-serif";
							ctx.textBaseline = "middle";
							ctx.fillStyle = "#0086FF";

							let text = "CAVOK";
							let textX = Math.round((width - ctx.measureText(text).width) / 2);
							let textY = height / 2;

							ctx.fillText(text, textX, textY);
							ctx.save();
						}

					}
				}]
			}
		};
	},
	props: {
		app: Object,
		msgs: Object,
	},
	mounted() {
		if(this.$route.query.icao) {
			this.icaoInput = this.$route.query.icao;
			this.getWeatherWithICAO();
		}
	},
	methods: {
		loadWeather() {
			if(!this.weather) {
				this.loading = false;
				this.$t(this.msgs["icaoCode"] || 'weatherView.something-went-wrong');
				return;
			}

			this.putCloudsOnGraph()
			this.createForecast();
			this.loading = false;
			this.inputError = null;
		},
		async getWeatherWithICAO() {
			if (!this.icaoInput) {
				this.inputError = this.$t('weatherView.icao-empty')
				return;
			}

			this.loading = true;

			window.newEvent("WEATHER", {
				icao: this.icaoInput
			})
			this.weather = await this.$rest.getWeatherWithICAO(this.icaoInput);
			this.loadWeather();
		},

		/**
		 * @param {number} lat
		 * @param {number} lon
		 */
		async getWeatherWithLocation(lat, lon) {
			this.loading = true;
			this.weather = await this.$rest.getWeatherWithCoordinates(lat,lon);
			this.loadWeather();
		},
		putCloudsOnGraph() {
			if (!this.weather) return null;
			this.chart.data.datasets[0].data = [];
			let maxCloud = 5000;
			this.weather.metar.clouds.forEach(cloud => {
				if (cloud.height > maxCloud) maxCloud = cloud.height;

				let clouds = [];
				let count = 0;
				if (cloud.quantity === "FEW") {
					count = 2;
				} else if (cloud.quantity === "SCT") {
					count = 4;
				} else if (cloud.quantity === "BKN") {
					count = 6;
				} else if (cloud.quantity === "OVC") {
					count = 8;
				}

				for (let i = 0; i < count; i++) {
					clouds = [...clouds, {
						y: cloud.height,
						x: ((100 / count) * i) + ((90 / count) * 0.5),
					}]
				}

				this.chart.data.datasets[0].data = [...this.chart.data.datasets[0].data, ...clouds];
			});

			this.chart.options.scales.y.max = (Math.ceil((maxCloud / 1000)) * 1000) + 3000;

		},
		createForecast() {
			if (!this.weather) return null;

			this.forecast = [];
			const currenDate = new Date();

			const offset = currenDate.getTimezoneOffset() / 60;
			const startDate = new Date(currenDate.getFullYear(), currenDate.getMonth(), this.weather.taf.validity.startDay, this.weather.taf.validity.startHour - offset);
			const endDate = new Date(currenDate.getFullYear(), currenDate.getMonth(), this.weather.taf.validity.endDay, this.weather.taf.validity.endHour - offset);
			const diff = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60) // hours

			for (let i = 0; i < diff; i++) {
				const current = new Date(startDate.getTime() + (i * (1000 * 60 * 60)));
				this.forecast = [...this.forecast, {
					date: current,
					day: this.$t('weekdays.' + current.getDay()),
					time: `${current.getHours().toString().padStart(2, '0')}:${current.getMinutes().toString().padStart(2, '0')}`,
					visibility: this.weather.taf.visibility.mainVisibility,
					windDirection: this.weather.taf.wind.directionDegrees,
					windSpeed: this.weather.taf.wind.speed + " " + this.weather.taf.wind.unit,
				}]
			}

			[
				...this.weather.taf.probs,
				...this.weather.taf.becmgs,
				...this.weather.taf.tempos,
				...this.weather.taf.inters,
				...this.weather.taf.fms
			].forEach(t => {
				const trendStart = new Date(currenDate.getFullYear(), currenDate.getMonth(), t.validity.startDay, t.validity.startHour - offset);
				const trendEnd = new Date(currenDate.getFullYear(), currenDate.getMonth(), t.validity.endDay, t.validity.endHour - offset);
				this.forcast = this.forecast.map(f => {
					if (f.date.getTime() >= trendStart.getTime() && f.date.getTime() <= trendEnd.getTime()) {
						if (t.wind) {
							if (t.wind.directionDegrees) f.windDirection = t.wind.directionDegrees;
							if (t.wind.speed) f.windSpeed = t.wind.speed + " " + t.wind.unit;
						}
						if (t.visibility) {
							f.visibility = t.visibility.mainVisibility;
						}
					}
					return f;
				});
			})
		},
    addWeatherToBriefing() {
      if(!this.icaoInput) return;
      if(!this.weather) return;

      briefingmanager.addSubGroup("weather", {
        key: this.icaoInput,
        title: `
          <div style="display: flex; width: 100%; flex-direction: column; justify-content: center; align-items: center;">
            ${(((this.weather || {}).distance || {}).value ? (`
            <h4 style="text-align: center">
              #{weatherView.distanceFrom} <strong>${this.weather.distance.from.name}(${this.weather.distance.from.code})</strong> #{weatherView.fromAirport}: <strong>${Math.round(this.weather.distance.value)} km</strong>
            </h4>`) : (''))}
            <h2 style="text-align: center; margin: 0">
              ${this.weather.metar.airport.name} (${this.weather.metar.airport.icao})
            </h2>
          </div>
        `,
				localTitle: `${this.weather.metar.airport.name} (${this.weather.metar.airport.icao})`,
        tables: [
          {
            key: "fullWeather",
            rows: [
              [
                {
									style: "font-weight: bold; text-align: center; flex: 1;",
                  value: 'METAR',
                },
                {
									style: "font-weight: bold; text-align: center; flex: 1;",
                  value: 'TAF',
                },
              ],
              [
                {
									style: "flex: 1; text-align: center;",
                  value: this.weather.metar.message,
                },
                {
									style: "flex: 1; text-align: center;",
                  value: this.weather.taf.message,
                },
              ]
            ]
          }
        ]
      });
      this.briefingReportAdded = true;
    }
	},
	watch: {
		weather() {
      this.briefingReportAdded = briefingmanager.isTableExists('weather', this.icaoInput, 'fullWeather');
			this.runwayDirection = (((this.weather.airport || {}).airportRunways || [])[0] || {}).trueDirection || null;
		}
	},
  updated: function () {
    this.$nextTick(function () {

      if(this.forecast.length > 0) {
        try {
            new ScrollBooster({
                viewport: document.querySelector('.scroll-viewport'),
                scrollMode: 'transform',
                direction: "horizontal",
                emulateScroll: true,
            });
      } catch (error) {
        console.log(error);
      }
      }
    })
  }
}
</script>

<style scoped>
.info-card-title {
	background-color: #0086FF;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
}

.message {
	border-radius: 0.5rem;
	background: #e5e7eb;
	padding: 1rem;
	border: solid 1px rgb(145, 145, 145);
	font-size: 1.3rem;
	vertical-align: middle;
	display: flex;
	align-content: center;
	align-items: center;
}


.webcams.text-primary, .webcams.text-primary:hover, .webcams.text-primary:active, .webcams.text-primary:visited {
  color: #0086FF !important;
}

.webcams.text-primary:hover{
  text-decoration: underline;
}
</style>
