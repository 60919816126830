<template>
  <section class="tafForecast">
    <div class="cardHeader">
      <div class="headerSubtitle">
        {{day || '-'}}
      </div>
      <div class="headerTitle">
        {{time || '-'}}
      </div>
    </div>
    <div class="cardBody">
      <div class="propContainer">
        <div class="propValue">
          {{visibility || '-'}}
        </div>
        <div class="propTitle">
          {{ $t('weatherView.visibility') }}
        </div>
      </div>
      <div class="propContainer">
        <div class="propValue">
          <img v-if="windDirection" class="windArrow"  src="@/assets/wind-arrow.svg" :style="style" />
          <span>{{windDirection ? `${windDirection}°` : '-'}}</span>
        </div>
        <div class="propTitle">
          {{ $t('weatherView.windDirection') }}
        </div>
      </div>
      <div class="propContainer">
        <div class="propValue">
          {{windSpeed || '-'}}
        </div>
        <div class="propTitle">
          {{ $t('weatherView.windSpeed') }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
	name: 'TafForecast',
	props: {
		day: String,
    time: String,
    visibility: String,
    windDirection: Number,
    windSpeed: String,
	},
	components: {

	},
  methods: {

  },
  computed: {
    style () {
      return `transform: rotate(${this.windDirection -90}deg)`
    }
  },
  mounted() {
  },
}
</script>

<style>
  .tafForecast {
    display: inline-block;
    margin: 0 0.5rem 0;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .tafForecast .cardHeader{
    background: #0086FF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 3rem;
    color: white;
  }
  .tafForecast .cardHeader .headerSubtitle{
    text-transform: uppercase;
    font-size: 0.9em;
  }
  .tafForecast .cardHeader .headerTitle{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.8em;
  }
  .tafForecast .cardBody{
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 3rem;
    color: #0086FF;
  }
  .tafForecast .cardBody .propContainer{
    padding: 0.5rem 0;
    text-align: center;
  }

  .tafForecast .cardBody .propContainer .propValue{
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
  }
  .tafForecast .cardBody .propContainer .propValue .windArrow{
    width: 1.3rem;
    /* height: 1.3rem; */
    margin-right: 0.5rem;
  }

  .tafForecast .cardBody .propContainer .propTitle{
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.7em;
  }
</style>
