<template>
  <section class="windCompass" ref="windCompass">
    <div class="compassDisplay" ref="compassDisplay">
      <div class="compassDisplayInner">
        <h1 class="text-blue-dark-app">{{humanReadableDirection}}</h1>
        <h1 class="text-blue-dark-app heading my-2">{{direction ? direction + '°' : '-'}}</h1>
        <h6>{{$t('weatherView.windDirection')}}</h6>
      </div>
    </div>
    <img src="@/assets/wind-arrow.svg" class="mx-auto arrow" ref="arrow" />
    <img src="@/assets/runway.svg" class="compass-runway" ref="runway"/>
    <div class="compassBorder"></div>
  </section>
</template>

<script>

export default {
	name: 'WindCompass',
	props: {
		direction: Number,
    runwayDirection: Number,
	},
	components: {

	},
  computed: {
    humanReadableDirection() {
      const windDirection = this.direction;
      if(!windDirection) return "-";

      if (windDirection > 22.5 && windDirection <= 67.5) {
        return this.$t('compass.NE');
      }
      if (windDirection > 67.5 && windDirection <= 112.5) {
        return this.$t('compass.E');
      }
      if (windDirection > 112.5 && windDirection <= 157.5) {
        return this.$t('compass.SE');
      }
      if (windDirection > 157.5 && windDirection <= 202.5) {
        return this.$t('compass.S');
      }
      if (windDirection > 202.5 && windDirection <= 247.5) {
        return this.$t('compass.SW');
      }
      if (windDirection > 247.5 && windDirection <= 292.5) {
        return this.$t('compass.W');
      }
      if (windDirection > 292.5 && windDirection <= 337.5) {
        return this.$t('compass.NW');
      }
      return this.$t('compass.N');
    },
  },
  methods: {
    animateArrow() {
      const { windCompass } = this.$refs;
      const { compassDisplay } = this.$refs;
      const transition = `${(this.arrowAnimationSpeed - 200) / 1000}s ease-out`;
      compassDisplay.style.transition = transition;
      windCompass.style.transition = transition;
      let targetDirectionDegrees = this.direction || 0;
      if (360 - targetDirectionDegrees < targetDirectionDegrees) {
        targetDirectionDegrees = -(360 - targetDirectionDegrees);
      }
      windCompass.style.transform = `rotate(${targetDirectionDegrees}deg)`;
      compassDisplay.style.transform = `rotate(${targetDirectionDegrees * -1}deg)`;
    },
    animateRunway() {
      const { runway } = this.$refs;
      if(!this.runwayDirection){
          runway.style.display = "none";
          return;
      }

      const transition = `0.3s ease-out`;
      runway.style.transition = transition;
      let targetDirectionDegrees = this.runwayDirection || 0;
      if (360 - targetDirectionDegrees < targetDirectionDegrees) {
        targetDirectionDegrees = -(360 - targetDirectionDegrees);
      }

      let arrowTargetDirectionDegrees = this.direction || 0;
      if (360 - arrowTargetDirectionDegrees < arrowTargetDirectionDegrees) {
        arrowTargetDirectionDegrees = -(360 - arrowTargetDirectionDegrees);
      }


      targetDirectionDegrees -= arrowTargetDirectionDegrees;
      runway.style.transform = `translate(-50%, -50%) rotate(${targetDirectionDegrees}deg)`;
    },
  },
  watch: {
    data() {
      this.animateArrow();
      this.animateRunway();
    },
    direction() {
      this.animateArrow();
      this.animateRunway();
    },
    runwayDirection() {
      this.animateArrow();
      this.animateRunway();
    },
  },
  mounted() {
    this.animateArrow();
    this.animateRunway();
  },
}
</script>

<style>
.windCompass {
  position: relative;
  display: inline-flex;

  width: 16rem;
  height: 16rem;
}

.compass-runway{
    height: 110%;
    width: 30px;
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    /* background: #00000090; */
    z-index: 0;
}

.compassBorder{
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  border-radius: 50%;
  border: double 4px rgb(209 213 219);
  z-index: -2;
}

.compassDisplay {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 2;
}

.compassDisplayInner{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  background: rgb(255, 255, 255);
  border: 2px solid ;
  aspect-ratio: 1/1;
  width: 12rem;
  z-index: 2;
}

.arrow {
  width: 3rem;
  height: 3rem;
  transform: rotate(90deg) translateX(-50%);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
</style>
